<script setup lang="ts">
import { useReCaptcha } from 'vue-recaptcha-v3'
import { addToNewsletterList } from '~/utils/newsletter'

defineProps({
  uniqueId: {
    type: String,
    required: true,
  },
})
const { t } = useI18n()

const sending = ref(false)
const submitted = ref(false)
const error = ref(false)
const openNewsletterModal = ref(false)

const email = ref()
const messageNewsletter = ref()
const recaptchaInstance = useReCaptcha()

const recaptcha = async () => {
  await recaptchaInstance?.recaptchaLoaded()
  return recaptchaInstance?.executeRecaptcha('getToken')
}

const submitForm = async () => {
  if (email.value) {
    sending.value = true
    const recaptchaToken = await recaptcha()
    const data = {
      recaptchaToken: '' + recaptchaToken,
      email: email.value,
    }

    let response

    try {
      response = await addToNewsletterList(data)
    }
    catch (e) {
    }

    if (response) {
      sending.value = false
      submitted.value = true
      messageNewsletter.value = t('form.message.success_newsletter')
    }
    else {
      sending.value = false
      submitted.value = true
      error.value = true
      messageNewsletter.value = t('form.message.error_add_customer_newsletter')
    }

    toggleNewsletterModal()
  }
  else {
    messageNewsletter.value = t('form.message.error_input_newsletter')
  }
}

const toggleNewsletterModal = () => {
  openNewsletterModal.value = !openNewsletterModal.value
}
</script>

<template>
  <div class="form-information">
    <UiElementsLoader v-if="sending" />
    <span v-if="messageNewsletter">{{ messageNewsletter }}</span>
    <form
      :name="'form-contact-' + uniqueId"
      @submit.prevent="submitForm"
    >
      <UiFormsInput
        v-model="email"
        class="newsletter-input"
        :unique-id="'newsletter-email-' + uniqueId"
        type="email"
        name="email"
        :place-holder="$t('form.placeholder.email')"
      >
        <UiIconsEnvelope />
      </UiFormsInput>
      <span class="cgu">{{ $t('newsletter.cgu') }}</span>
      <UiElementsButton type="submit">
        {{ $t('form.button.sign_in') }}
      </UiElementsButton>
    </form>
  </div>

  <Teleport to="body">
    <UiElementsDefaultModal
      v-if="submitted && openNewsletterModal"
      id="newsletter-modal"
    >
      <template #modal-content>
        <div class="newsletter-modal-content">
          <UiElementsMessage
            v-if="error == false"
            :title="$t('message.newsletter.title')"
            :text="$t('message.newsletter.success.text1')"
            :advisor-text="$t('message.newsletter.success.text2')"
          >
            <template #icon>
              <UiIconsSuccess />
            </template>
          </UiElementsMessage>
          <UiElementsMessage
            v-else
            :title="$t('message.newsletter.title')"
            :text="$t('message.newsletter.error')"
            :has-advisor="false"
          >
            <template #icon>
              <UiIconsCross class="error" />
            </template>
          </UiElementsMessage>
        </div>
      </template>
      <template #close-modal>
        <div
          class="modal-overlay--close"
          @click="toggleNewsletterModal"
        >
          <UiIconsCross />
        </div>
      </template>
    </UiElementsDefaultModal>
  </Teleport>
</template>

<style scoped lang="scss">
.form-information {
  position: relative;

  form {
    display: flex;
    flex-wrap: wrap;
    row-gap: 1em;

    :deep(.form-input) {
      flex: 1;
      @media (min-width: map-get($grid-breakpoints, md)) {
        min-width: 365px;
        border-width: 2px;
      }
    }

    :deep(button), :deep(a) {
      order: 3;
      width: 100%;

      @media (min-width: map-get($grid-breakpoints, md)) {
        order: 2;
        width: auto;
      }
    }

    .newsletter-input :deep(input):focus {
      border: 2px solid var(--color-navy-150)
    }

    .cgu {
      flex-basis: 100%;
      width: 0;
      order: 2;

      @media (min-width: map-get($grid-breakpoints, md)) {
        order: 3;
      }
    }
  }
}

#newsletter-modal {
  .modal-overlay--modal {
    .modal-overlay--close {
      position: absolute;
      top: 1.5rem;
      right: 1.5rem;
      cursor: pointer;
    }
  }
}
</style>
